import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-layout" }

import TopBar from '@/components/TopBar/index.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_el_header = _resolveComponent("el-header")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, { class: "main-wrap" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, { class: "el-header" }, {
          default: _withCtx(() => [
            _createVNode(TopBar)
          ]),
          _: 1
        }),
        _createVNode(_component_el_main, { class: "el-main" }, {
          default: _withCtx(() => [
            _createVNode(_component_RouterView)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})